import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Scrollbar from 'react-scrollbars-custom';
import dayjs from 'dayjs';
import config from 'config/common';
import lib from 'lib/commons';
import Spinner from 'components/Spinner';

Modal.setAppElement('#root');

const ModalHistory = ({
	userHistory,
	isGettingHistory,
	isExchangingShopHistory,
	exchangeShopHistory,
	getHistory,
	modalStatus,
	closeModal,
	exchangeHistory,
}) => {
	const [currentTab, setCurrentTab] = useState('league');

	useEffect(() => {
		if (!isGettingHistory) {
			getHistory();
		}
		if (!isExchangingShopHistory) {
			exchangeShopHistory();
		}
	}, []);

	const afterOpenModal = () => {};

	const selfClose = (event) => {
		event.preventDefault();
		closeModal();
	};

	return (
		<Modal
			isOpen={modalStatus}
			onAfterOpen={afterOpenModal}
			onRequestClose={selfClose}
			contentLabel="Example Modal"
			portalClassName="ReactModalPortal"
			overlayClassName=""
			className="animated fadeInDown faster modal-history"
		>
			<h2>Lịch sử</h2>
			<div className="modal-description">
				<div className="row no-gutters justify-content-center btn-his-wrap">
					<a
						href="#"
						className={`btn-his col-6${
							currentTab == 'league' ? ' btn-his--active' : ''
						}`}
						onClick={(e) => {
							e.preventDefault();
							if (currentTab == 'league') {
								return false;
							}
							setCurrentTab('league');
						}}
					>
						Lịch sử giải đấu
					</a>
					<a
						href="#"
						className={`btn-his col-6${
							currentTab == 'shop' ? ' btn-his--active' : ''
						}`}
						onClick={(e) => {
							e.preventDefault();
							if (currentTab == 'shop') {
								return false;
							}
							setCurrentTab('shop');
						}}
					>
						Lịch sử shop
					</a>
				</div>
				{currentTab == 'league' && (
					<>
						{isGettingHistory || isExchangingShopHistory ? (
							<Spinner />
						) : (
							<>
								<table className="table-history">
									<thead>
										<tr>
											<th width="5%">STT</th>
											<th width="20%">Tên VP</th>
											<th width="10%">Hạng</th>
											<th width="30%">Giải đấu</th>
											<th width="15%">Trạng Thái</th>
											<th width="20%">Thời gian</th>
										</tr>
									</thead>
								</table>

								<Scrollbar style={{ height: '17vw' }} noScrollX={true}>
									{userHistory.length > 0 ? (
										<table className="table-history">
											<tbody>
												{userHistory.map((his, index) => (
													<tr key={index}>
														<td width="5%">{index + 1}</td>
														<td width="20%">{his.item_name}</td>
														<td width="10%">{his?.rank}</td>
														<td width="30%">{his?.league?.name}</td>
														<td width="15%">
															{his?.status == 'success' ? 'Đã gửi' : 'Đang gửi'}
														</td>
														<td width="20%">
															{dayjs(his?.created_at).format(
																'DD/MM/YYYY HH:mm:ss'
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<p>Chưa có lịch sử</p>
									)}
								</Scrollbar>
							</>
						)}
					</>
				)}

				{currentTab == 'shop' && (
					<>
						{isGettingHistory || isExchangingShopHistory ? (
							<Spinner />
						) : (
							<>
								<table className="table-history">
									<thead>
										<tr>
											<th width="5%">STT</th>
											<th width="30%">Tên VP</th>
											<th width="20%">Sự kiện</th>
											{/* <th width="30%">Giải đấu</th> */}
											<th width="15%">Trạng Thái</th>
											<th width="30%">Thời gian</th>
										</tr>
									</thead>
								</table>

								<Scrollbar style={{ height: '17vw' }} noScrollX={true}>
									{exchangeHistory.length > 0 ? (
										<table className="table-history">
											<tbody>
												{exchangeHistory.map((his, index) => (
													<tr key={index}>
														<td width="5%">{index + 1}</td>
														<td width="30%">{his.item_name}</td>
														<td width="20%">Shop đổi điểm</td>

														<td width="15%">
															{his?.status == 'success' ? 'Đã gửi' : 'Đang gửi'}
														</td>
														<td width="30%">
															{dayjs(his?.created_at).format(
																'DD/MM/YYYY HH:mm:ss'
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<p>Chưa có lịch sử</p>
									)}
								</Scrollbar>
							</>
						)}
					</>
				)}
			</div>
			<a
				onClick={(event) => selfClose(event)}
				className="close"
				data-dismiss="modal"
				aria-label="Close"
			>
				×
			</a>
		</Modal>
	);
};
export default ModalHistory;
