export default {
	vi: {
		domain: 'fvpl.fo4.garena.vn',
		subTitle1:
			'Hoàn thành 3 trận bất kì, và chia sẻ trang sự kiện để nhận lượt quay quà miễn phí (tối đa 4 lượt/ngày). Các lượt quay quà này sẽ được tích lũy, giúp bạn nhận thêm quà tại các mốc nhất định',
		subTitle2:
			'Chọn đội tuyển yêu thích của bạn và tiếp lửa cho họ trong chặng đường sắp tới. Với bất kì chiến thắng nào của đội tuyển, bạn sẽ nhận được 10 điểm “RỰC LỬA” để đồi quà',
		subTitle3:
			'Trổ tài dự đoán tỉ số các trận đấu tại FECHC. Với mỗi dự đoán chính xác sẽ đem về 20 điểm “RỰC LỬA”',
		subTitle4:
			'Cố gắng tích lũy nhiều điểm nhất có thể để đua top BXH toàn server. Top 100 HLV đạt được số điểm cao nhất sẽ nhận về những phần thưởng vô cùng giá trị',

		notice: 'Thông báo',
		confirm: 'Xác nhận',
		cancel: 'Huỷ',
		congrats: 'Chúc mừng!!!',
		claimMessage: `Bạn sẽ nhận được <span class="highlight"><strong>[xxx]</strong></span><br>sau khi nhấn "Xác nhận"`,
		youReceived: 'Bạn đã nhận được',
		youReceivedMain: 'Bạn đã nhận được vật phẩm chính',
		defaultError: 'Hiện không thể thực hiện, vui lòng thử lại sau',
		not_logged_in: 'Bạn chưa đăng nhập',
		missing_account_id: 'Tài khoản của bạn chưa đăng nhập vào game',
		region_invalid: 'Khu vực không được hỗ trợ cho sự kiện',
		version_incompatible:
			'Sự kiện đã được nâng cấp. Bạn vui lòng tải lại sự kiện',
		item_not_found: 'Không tìm thấy vật phẩm',
		item_invalid: 'Chưa đến thời điểm nhận vật phẩm',
		item_unavailable: 'Chưa đến thời điểm nhận vật phẩm',
		event_unstarted: 'Sự kiện chưa bắt đầu',
		event_ended: 'Sự kiện đã kết thúc',
		already_shared: 'Bạn đã chia sẻ trước đó rồi!',
		team_playing:
			'Tính năng này được tạm khóa để cập nhật kết quả, vui lòng quay lại sau',
		not_contributed: 'Hãy chơi 1 trận',
		spin_ended: 'Sự kiện tích điểm đổi quà đã kết thúc',
		user_processing: 'Hệ thống đang trao điểm, vui lòng thử lại sau 10 phút',
		AlreadyRegisteredLeagueError: 'Bạn đã đăng ký tham gia giải đấu rồi.',
		RegisterClosedError: 'Đã quá thời gian huỷ đăng ký tham gia giải đấu',
		LeagueFinishedError: 'Giải đấu đã kết thúc',
		PlayerInATeamError:
			'Huấn luyện viên này đang nhận được lời mời hoặc đã tham gia đội. <br /> Bạn vui lòng chọn huấn luyện viên khác',
		PlayerHavingInvitationError:
			'Huấn luyện viên này đang nhận được lời mời hoặc đã tham gia đội. <br /> Bạn vui lòng chọn huấn luyện viên khác',
		EnoughPlayerInLeagueError:
			'Giải đấu đã đạt giới hạn đăng ký. Vui lòng chọn giải đấu khác',
		RegisterClosedError:
			'Giải đấu đã đóng đăng ký. Bạn vui lòng đăng ký giải đấu khác.',
		LastWeekResultNotUpdatedError:
			'Cập nhật kết quả tuần trước bị lỗi. Bạn vui lòng tải lại trang.',
		CannotRegisterDueToLastWeekHighRankError:
			'Bạn không thể đăng ký giải đấu. Bởi vì đã đạt thứ hạng cao ở giải trước.',
		CannotHaveSameScoreError: 'Tỷ số trận đấu không được giống nhau.',
		NoPermissionError: 'Bạn không có quyền.',
		RoundNotStartedError: 'Vòng đấu chưa bắt đầu.',
		FormationValueNotSatisfiedError:
			'Giá trị đội hình không đủ điều kiện của giải đấu',
		CannotGetMatchResult: 'Kết quả trận đấu không được cập nhật.',
		MatchAlreadyHadScoreError: 'Trận đấu đã có tỷ số.',
		InvalidTimeToUpdateScoreError: 'Lỗi thời gian cập nhật tỷ số.',
		NeedManualUpdateScoreError: 'Lỗi cập nhật tỷ số.',
		LeagueConfigError: 'Lỗi cài đặt giải đấu.',
		RankingLastSeasonNotSatisfied: 'Lỗi xếp hạng mùa trước.',
		LeagueFinishedError: 'Lỗi kết thúc giải đấu.',
		HavingUidListError: 'Lỗi UID danh sách.',
		UidNotExistError: 'UID không tồn tại.',
		NeedUpdateInfoError: 'Bạn cần cập nhật thông tin.',
		HavingPlayerAlreadyRegisteredLeague: 'Đã có thành viên đăng ký giải đấu.',
		CannotRegisterSameWeek:
			'Bạn không đăng ký nhiều hơn 1 giải đấu trong 1 tuần.',
		TeamNameContainsBadWord: 'Tên đội không hợp lệ',
	},
	en: {
		domain: 'salehunt.ff.garena.vn',
		logoSite: '/images/logo-pk.png',
		saleTag: 'Sale',
		refreshSale: 'Refresh discount',
		buyText: 'Buy',
		boughtText: 'Bought',
		cantBuyText: 'Can not buy',
		guideText: 'Guide text...',
		ticketLabel: 'Sale off ticket',
		confirmBuy: `Do you want to buy this item?<br>Select your discount ticket`,
		warning:
			'Bạn chưa chọn vé giảm giá<br>bạn có muốn tiếp tục mua vật phẩm không?',
		rerollConfirm:
			'Use [xxx] <img src="/images/icon-diamond.png" class="icon-diamond" alt=""/> to refresh?',
		purchasedError: 'You have already used this discount ticket',
		rerollSuccess: 'Successfully refresh',
		ruleTitle: 'Information',
		rule: '<p>Lorem</p>',
		historyTitle: 'History',
		timeField: 'Time',
		itemField: 'Item',
		priceField: 'Price',
		noHistory: 'No history',
		menuHome: 'Home',

		notice: 'Notice',
		confirm: 'Confirm',
		cancel: 'Cancel',
		congrats: 'Gongratulation!!!',
		claimMessage: `Bạn sẽ nhận được <span class="highlight"><strong>[xxx]</strong></span><br>sau khi nhấn "Xác nhận"`,
		youReceived: 'You received',
		youReceivedMain: 'You received main prize',
		defaultError: 'Error occurred, please try again later',
		unauthorized: 'Please login',
		account_not_found: 'Your account is not activated ingame',
		region_invalid: 'Region is invalid',
		version_incompatible: 'Event is upgraded. Please reload your site',
		item_not_found: 'No item found',
		item_invalid: 'Item is invalid',
		item_unavailable: 'Item is not available yet',
		event_unstarted: 'Event has not started yet',
		event_stopped: 'Event has ended',
		limit_exceeded: 'You have reach the item limitation',
		packs_purchased: 'You have already bought this pack',
		reroll_limit_exceeded: 'You have reach refresh limitation',
		pack_not_found: 'No pack found',
		discount_not_found: 'Discount rate is not exist',
		discount_used: 'Discount have already used',
		spent_unreached: 'You need to spent more to buy this item',
		discount_unapplicable: 'Discount can not be applied',
	},
};
