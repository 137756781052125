import request from 'lib/request';
import config from 'config/app';
import lib from 'lib/commons';
import lang from 'lng/index';
const lng = lang[config.lng];

export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_REQUEST_ERROR = 'CURRENT_USER_REQUEST_ERROR';
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS';
export const CURRENT_USER_FETCHING = 'CURRENT_USER_FETCHING';

export const GET_CONFIG_REQUEST_ERROR = 'GET_CONFIG_REQUEST_ERROR';
export const GET_CONFIG_REQUEST_SUCCESS = 'GET_CONFIG_REQUEST_SUCCESS';
export const GET_CONFIG_FETCHING = 'GET_CONFIG_FETCHING';

export const CURRENT_LEAGUE_REQUEST_ERROR = 'CURRENT_LEAGUE_REQUEST_ERROR';
export const CURRENT_LEAGUE_REQUEST_SUCCESS = 'CURRENT_LEAGUE_REQUEST_SUCCESS';
export const CURRENT_LEAGUE_FETCHING = 'CURRENT_LEAGUE_FETCHING';

export const REGISTER_LEAGUE_REQUEST_ERROR = 'REGISTER_LEAGUE_REQUEST_ERROR';
export const REGISTER_LEAGUE_REQUEST_SUCCESS =
	'REGISTER_LEAGUE_REQUEST_SUCCESS';
export const REGISTER_LEAGUE_FETCHING = 'REGISTER_LEAGUE_FETCHING';

export const UNREGISTER_LEAGUE_REQUEST_ERROR =
	'UNREGISTER_LEAGUE_REQUEST_ERROR';
export const UNREGISTER_LEAGUE_REQUEST_SUCCESS =
	'UNREGISTER_LEAGUE_REQUEST_SUCCESS';
export const UNREGISTER_LEAGUE_FETCHING = 'UNREGISTER_LEAGUE_FETCHING';

export const COLLABORATOR_REQUEST_ERROR = 'COLLABORATOR_REQUEST_ERROR';
export const COLLABORATOR_REQUEST_SUCCESS = 'COLLABORATOR_REQUEST_SUCCESS';
export const COLLABORATOR_FETCHING = 'COLLABORATOR_FETCHING';

export const UPDATE_SCORE_ERROR = 'UPDATE_SCORE_ERROR';
export const UPDATE_SCORE_SUCCESS = 'UPDATE_SCORE_SUCCESS';
export const UPDATE_SCORE_FETCHING = 'UPDATE_SCORE_FETCHING';

export const UPDATE_MANUAL_SCORE_ERROR = 'UPDATE_MANUAL_SCORE_ERROR';
export const UPDATE_MANUAL_SCORE_SUCCESS = 'UPDATE_MANUAL_SCORE_SUCCESS';
export const UPDATE_MANUAL_SCORE_FETCHING = 'UPDATE_MANUAL_SCORE_FETCHING';

export const GET_HISTORY_ERROR = 'GET_HISTORY_ERROR';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const IS_GETTING_HISTORY = 'IS_GETTING_HISTORY';

export const UPDATE_INFO_ERROR = 'UPDATE_INFO_ERROR';
export const UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS';
export const IS_UPDATING_INFO = 'IS_UPDATING_INFO';

export const CREATE_TEAM_ERROR = 'CREATE_TEAM_ERROR';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const IS_CREATING_TEAM = 'IS_CREATING_TEAM';

export const CHECK_INVITE_PLAYER_ERROR = 'CHECK_INVITE_PLAYER_ERROR';
export const CHECK_INVITE_PLAYER_SUCCESS = 'CHECK_INVITE_PLAYER_SUCCESS';
export const IS_CHECKING_INVITE_PLAYER = 'IS_CHECKING_INVITE_PLAYER';

export const GET_TEAM_ERROR = 'GET_TEAM_ERROR';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const IS_GETTING_TEAM = 'IS_GETTING_TEAM';

export const DISMISS_TEAM_ERROR = 'DISMISS_TEAM_ERROR';
export const DISMISS_TEAM_SUCCESS = 'DISMISS_TEAM_SUCCESS';
export const IS_DISMISSING_TEAM = 'IS_DISMISSING_TEAM';

export const KICK_PLAYER_ERROR = 'KICK_PLAYER_ERROR';
export const KICK_PLAYER_SUCCESS = 'KICK_PLAYER_SUCCESS';
export const IS_KICKING_PLAYER = 'IS_KICKING_PLAYER';

export const FILTER_LIST_TEAM_ERROR = 'FILTER_LIST_TEAM_ERROR';
export const FILTER_LIST_TEAM_SUCCESS = 'FILTER_LIST_TEAM_SUCCESS';
export const IS_FILTERING_LIST_TEAM = 'IS_FILTERING_LIST_TEAM';

export const REQUEST_TO_TEAM_ERROR = 'REQUEST_TO_TEAM_ERROR';
export const REQUEST_TO_TEAM_SUCCESS = 'REQUEST_TO_TEAM_SUCCESS';
export const IS_REQUESTING_TO_TEAM = 'IS_REQUESTING_TO_TEAM';

export const CANCEL_REQUEST_TO_TEAM_ERROR = 'CANCEL_REQUEST_TO_TEAM_ERROR';
export const CANCEL_REQUEST_TO_TEAM_SUCCESS = 'CANCEL_REQUEST_TO_TEAM_SUCCESS';
export const CANCEL_IS_REQUESTING_TO_TEAM = 'CANCEL_IS_REQUESTING_TO_TEAM';

export const RESPONSE_TEAM_REQUEST_ERROR = 'RESPONSE_TEAM_REQUEST_ERROR';
export const RESPONSE_TEAM_REQUEST_SUCCESS = 'RESPONSE_TEAM_REQUEST_SUCCESS';
export const IS_RESPONSING_TEAM_REQUEST = 'IS_RESPONSING_TEAM_REQUEST';

export const QUIT_TEAM_ERROR = 'QUIT_TEAM_ERROR';
export const QUIT_TEAM_SUCCESS = 'QUIT_TEAM_SUCCESS';
export const IS_QUITING_TEAM = 'IS_QUITING_TEAM';

export const INVITE_PLAYER_TEAM_ERROR = 'INVITE_PLAYER_TEAM_ERROR';
export const INVITE_PLAYER_TEAM_SUCCESS = 'INVITE_PLAYER_TEAM_SUCCESS';
export const IS_INVITING_PLAYER_TEAM = 'IS_INVITING_PLAYER_TEAM';

export const READ_NOTIFICATION_ERROR = 'READ_NOTIFICATION_ERROR';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const IS_READING_NOTIFICATION = 'IS_READING_NOTIFICATION';

export const RESPONSE_NOTIFICATION_ERROR = 'RESPONSE_NOTIFICATION_ERROR';
export const RESPONSE_NOTIFICATION_SUCCESS = 'RESPONSE_NOTIFICATION_SUCCESS';
export const IS_RESPONSING_NOTIFICATION = 'IS_RESPONSING_NOTIFICATION';

export const GET_SHOP_REQUEST_ERROR = 'GET_SHOP_REQUEST_ERROR';
export const GET_SHOP_REQUEST_SUCCESS = 'GET_SHOP_REQUEST_SUCCESS';
export const GET_SHOP_FETCHING = 'GET_SHOP_FETCHING';

export const EXCHANGE_SHOP_ERROR = 'EXCHANGE_SHOP_ERROR';
export const EXCHANGE_SHOP_SUCCESS = 'EXCHANGE_SHOP_SUCCESS';
export const EXCHANGE_SHOP = 'EXCHANGE_SHOP';

export const EXCHANGE_SHOP_HISTORY_ERROR = 'EXCHANGE_SHOP_HISTORY_ERROR';
export const EXCHANGE_SHOP_HISTORY_SUCCESS = 'EXCHANGE_SHOP_HISTORY_SUCCESS';
export const EXCHANGE_HISTORY_SHOP = 'EXCHANGE_HISTORY_SHOP';

export const GET_POINT_RANKING_ERROR = 'GET_POINT_RANKING_ERROR';
export const GET_POINT_RANKING_SUCCESS = 'GET_POINT_RANKING_SUCCESS';
export const GET_POINT_RANKING = 'GET_POINT_RANKING';

export const isCurrentUserFetching = () => {
	return {
		type: CURRENT_USER_FETCHING,
	};
};

export const getCurrentUser = () => {
	return (dispatch, getState) => {
		dispatch(isCurrentUserFetching());
		request('api/user/get').then(function (response) {
			if (response.status == 'success') {
				dispatch(getCurrentUserSuccess(response));
			} else {
				if (response.error_code == 'no_account') {
					lib.showDownlad();
				}
				dispatch(getCurrentUserError(response));
			}
		});
	};
};

export const getCurrentUserSuccess = (response) => {
	return {
		type: CURRENT_USER_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const getCurrentUserError = (response) => {
	return {
		type: CURRENT_USER_REQUEST_ERROR,
		payload: response.payload,
	};
};

//GET SHOP
export const isGetConfigFetching = () => {
	return {
		type: GET_CONFIG_FETCHING,
	};
};

export const getConfig = () => {
	return (dispatch, getState) => {
		dispatch(isGetConfigFetching());
		request('api/user/get_config').then(function (response) {
			if (response.status == 'success') {
				dispatch(getConfigSuccess(response));
			} else {
				dispatch(getConfigError(response));
			}
		});
	};
};

export const getConfigSuccess = (response) => {
	return {
		type: GET_CONFIG_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const getConfigError = (response) => {
	return {
		type: GET_CONFIG_REQUEST_ERROR,
		payload: response.payload,
	};
};

//GET CURRENT LEAGUE
export const isGetCurrentLeagueFetching = () => {
	return {
		type: CURRENT_LEAGUE_FETCHING,
	};
};

export const getCurrentLeague = () => {
	return (dispatch, getState) => {
		dispatch(isGetCurrentLeagueFetching());
		request('api/user/get_current_league').then(function (response) {
			if (response.status == 'success') {
				dispatch(getCurrentLeagueSuccess(response));
			} else {
				dispatch(getCurrentLeagueError(response));
			}
		});
	};
};

export const getCurrentLeagueSuccess = (response) => {
	return {
		type: CURRENT_LEAGUE_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const getCurrentLeagueError = (response) => {
	return {
		type: CURRENT_LEAGUE_REQUEST_ERROR,
		payload: response.payload,
	};
};

//REGISTER LEAGUE
export const isRegisterLeagueFetching = () => {
	return {
		type: REGISTER_LEAGUE_FETCHING,
	};
};

export const registerLeague = (id, name) => {
	return (dispatch, getState) => {
		dispatch(isRegisterLeagueFetching());
		request('api/user/register_league', 'POST', {
			body: JSON.stringify({
				league_id: parseInt(id),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(registerLeagueSuccess(response));
				lib.showMessage(
					`Chúc mừng bạn đã đăng ký giải ${name} thành công. <br />Ban tổ chức giải sẽ sớm liên hệ với bạn.<br /><a href="${response?.payload?.current_league?.league?.additional_info}" target="_blank">${response?.payload?.current_league?.league?.additional_info}</a>`
				);
			} else {
				lib.showError(response.error_code);
				dispatch(registerLeagueError(response));
			}
		});
	};
};

export const registerLeagueSuccess = (response) => {
	return {
		type: REGISTER_LEAGUE_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const registerLeagueError = (response) => {
	return {
		type: REGISTER_LEAGUE_REQUEST_ERROR,
		payload: response.payload,
	};
};

//UNREGISTER LEAGUE
export const isUnregisterLeagueFetching = () => {
	return {
		type: UNREGISTER_LEAGUE_FETCHING,
	};
};

export const unregisterLeague = (data, name) => {
	return (dispatch, getState) => {
		dispatch(isUnregisterLeagueFetching());
		request('api/user/unregister_league', 'POST', {
			body: JSON.stringify(data),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(unregisterLeagueSuccess(response));
				lib.showMessage(`Bạn đã huỷ đăng ký giải ${name} thành công.`);
			} else {
				lib.showError(response.error_code);
				dispatch(unregisterLeagueError(response));
			}
		});
	};
};

export const unregisterLeagueSuccess = (response) => {
	return {
		type: UNREGISTER_LEAGUE_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const unregisterLeagueError = (response) => {
	return {
		type: UNREGISTER_LEAGUE_REQUEST_ERROR,
		payload: response.payload,
	};
};

//COLLABORATOR LEAGUE
export const isCollaboratorLeagueFetching = () => {
	return {
		type: COLLABORATOR_FETCHING,
	};
};

export const getCollaboratorLeague = (data, name) => {
	return (dispatch, getState) => {
		dispatch(isCollaboratorLeagueFetching());
		request(`api/user/get_league_for_collaborator?id=${data}`).then(function (
			response
		) {
			if (response.status == 'success') {
				dispatch(collaboratorLeagueSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(collaboratorLeagueError(response));
			}
		});
	};
};

export const collaboratorLeagueSuccess = (response) => {
	return {
		type: COLLABORATOR_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const collaboratorLeagueError = (response) => {
	return {
		type: COLLABORATOR_REQUEST_ERROR,
		payload: response.payload,
	};
};

//UPDATE SCORE
export const isUpdateScoreFetching = () => {
	return {
		type: UPDATE_SCORE_FETCHING,
	};
};

export const updateScore = (data, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isUpdateScoreFetching());
		request(`api/user/programmatic_update_score`, 'POST', {
			body: JSON.stringify(data),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(updateScoreSuccess(response));
				lib.showMessage('Cập nhật tỷ số thành công').then((res) => {
					closeModal();
				});
			} else {
				lib.showError(response.error_code);
				dispatch(updateScoreError(response));
			}
		});
	};
};

export const updateScoreSuccess = (response) => {
	return {
		type: UPDATE_SCORE_SUCCESS,
		payload: response.payload,
	};
};

export const updateScoreError = (response) => {
	return {
		type: UPDATE_SCORE_ERROR,
		payload: response.payload,
	};
};

//UPDATE MANUAL SCORE
export const isUpdateManualScoreFetching = () => {
	return {
		type: UPDATE_MANUAL_SCORE_FETCHING,
	};
};

export const updateManualScore = (data, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isUpdateManualScoreFetching());
		request(`api/user/manual_set_score`, 'POST', {
			body: JSON.stringify(data),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(updateManualScoreSuccess(response));
				lib.showMessage('Cập nhật tỷ số thành công').then((res) => {
					closeModal();
				});
			} else {
				lib.showError(response.error_code);
				dispatch(updateManualScoreError(response));
			}
		});
	};
};

export const updateManualScoreSuccess = (response) => {
	return {
		type: UPDATE_MANUAL_SCORE_SUCCESS,
		payload: response.payload,
	};
};

export const updateManualScoreError = (response) => {
	return {
		type: UPDATE_MANUAL_SCORE_ERROR,
		payload: response.payload,
	};
};

//get history
export const isGettingHistory = () => {
	return {
		type: IS_GETTING_HISTORY,
	};
};

export const getHistory = () => {
	return (dispatch, getState) => {
		dispatch(isGettingHistory());
		request('api/user/get_reward_history').then(function (response) {
			if (response.status == 'success') {
				dispatch(getHistorySuccess(response));
			} else {
				dispatch(getHistoryError(response));
			}
		});
	};
};

export const getHistorySuccess = (response) => {
	return {
		type: GET_HISTORY_SUCCESS,
		payload: response.payload,
	};
};

export const getHistoryError = (response) => {
	return {
		type: GET_HISTORY_ERROR,
		payload: response.payload,
	};
};

//REGISTER LEAGUE
export const isUpdateInfoFetching = () => {
	return {
		type: IS_UPDATING_INFO,
	};
};

export const updateInfo = (data, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isUpdateInfoFetching());
		request('api/user/update_user_info', 'POST', {
			body: JSON.stringify(data),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(updateInfoSuccess(response));
				lib
					.showMessage(`Chúc mừng bạn đã cập nhật thông tin thành công.`)
					.then((res) => {
						closeModal();
					});
			} else {
				lib.showError(response.error_code);
				dispatch(updateInfoError(response));
			}
		});
	};
};

export const updateInfoSuccess = (response) => {
	return {
		type: UPDATE_INFO_SUCCESS,
		payload: response.payload,
	};
};

export const updateInfoError = (response) => {
	return {
		type: UPDATE_INFO_ERROR,
		payload: response.payload,
	};
};

//CREATE TEAM
export const isCreatingTeam = () => {
	return {
		type: IS_CREATING_TEAM,
	};
};

export const createTeam = (data, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isCreatingTeam());
		request('api/user/create_team', 'POST', {
			body: JSON.stringify(data),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(createTeamSuccess(response));
				lib.showMessage(`Chúc mừng bạn đã tạo đội thành công.`).then((res) => {
					closeModal();
				});
			} else {
				lib.showError(response.error_code);
				dispatch(createTeamError(response));
			}
		});
	};
};

export const createTeamSuccess = (response) => {
	return {
		type: CREATE_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const createTeamError = (response) => {
	return {
		type: CREATE_TEAM_ERROR,
		payload: response.payload,
	};
};
//CHECK INVITE PLAYER
export const isCheckingInvitePlayer = () => {
	return {
		type: IS_CHECKING_INVITE_PLAYER,
	};
};

export const checkingInvitePlayer = (uid) => {
	return (dispatch, getState) => {
		dispatch(isCheckingInvitePlayer());
		request('api/user/can_invite_player', 'POST', {
			body: JSON.stringify({
				uid: uid,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(checkingInvitePlayerSuccess(response));
				if (response.payload.can_invite) {
					lib.showMessage(`Huấn luyện viên này chưa gia nhập đội nào.`);
				} else {
					lib.showMessage(
						`Huấn luận viên này đã gia nhập đội rồi. Bạn vui lòng chọn huấn luyện viên khác.`
					);
				}
			} else {
				lib.showError(response.error_code);
				dispatch(checkingInvitePlayerError(response));
			}
		});
	};
};

export const checkingInvitePlayerSuccess = (response) => {
	return {
		type: CHECK_INVITE_PLAYER_SUCCESS,
		payload: response.payload,
	};
};

export const checkingInvitePlayerError = (response) => {
	return {
		type: CHECK_INVITE_PLAYER_ERROR,
		payload: response.payload,
	};
};
//GET TEAM
export const isGetTeamFetching = () => {
	return {
		type: IS_GETTING_TEAM,
	};
};

export const getTeam = (data) => {
	return (dispatch, getState) => {
		dispatch(isGetTeamFetching());
		request(`api/user/get_team?uid=${data ? data : ''}`).then(function (
			response
		) {
			if (response.status == 'success') {
				dispatch(getTeamSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(getTeamError(response));
			}
		});
	};
};

export const getTeamSuccess = (response) => {
	return {
		type: GET_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const getTeamError = (response) => {
	return {
		type: GET_TEAM_ERROR,
		payload: response.payload,
	};
};
//DISMISS TEAM
export const isDismissingTeam = () => {
	return {
		type: IS_DISMISSING_TEAM,
	};
};

export const dismissTeam = (id, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isDismissingTeam());
		request('api/user/dismiss_team', 'POST', {
			body: JSON.stringify({
				id: parseInt(id),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(dismissTeamSuccess(response));
				lib.showMessage(`Bạn đã giải tán đội thành công.`).then((res) => {
					closeModal();
				});
			} else {
				lib.showError(response.error_code);
				dispatch(dismissTeamPlayerError(response));
			}
		});
	};
};

export const dismissTeamSuccess = (response) => {
	return {
		type: DISMISS_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const dismissTeamPlayerError = (response) => {
	return {
		type: DISMISS_TEAM_ERROR,
		payload: response.payload,
	};
};
//KICK PLAYER
export const isKickingPlayer = () => {
	return {
		type: IS_KICKING_PLAYER,
	};
};

export const kickPlayer = (uid, stateUid, playerUid, setPlayerUid) => {
	return (dispatch, getState) => {
		dispatch(isKickingPlayer());
		request('api/user/kick_player', 'POST', {
			body: JSON.stringify({
				uid: uid,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(kickPlayerSuccess(response));

				lib
					.showMessage(`Bạn đã loại thành công thành viên ra khỏi đội.`)
					.then((res) => {
						setPlayerUid({
							...playerUid,
							[stateUid]: ' ',
						});
					});
				// lib.showMessage(`Bạn đã loại thành công thành viên ra khỏi đội.`).then(res => {
				//   closeModal()
				// })
			} else {
				lib.showError(response.error_code);
				dispatch(kickPlayerError(response));
			}
		});
	};
};

export const kickPlayerSuccess = (response) => {
	return {
		type: KICK_PLAYER_SUCCESS,
		payload: response.payload,
	};
};

export const kickPlayerError = (response) => {
	return {
		type: KICK_PLAYER_ERROR,
		payload: response.payload,
	};
};
//FILTER LIST TEAM
export const isFilteringListTeam = () => {
	return {
		type: IS_FILTERING_LIST_TEAM,
	};
};

export const fliterListTeam = (team_name, captain_uid) => {
	return (dispatch, getState) => {
		dispatch(isFilteringListTeam());
		request('api/user/filter_list_team', 'POST', {
			body: JSON.stringify({
				team_name: team_name,
				captain_uid: captain_uid,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(fliterListTeamSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(fliterListTeamError(response));
			}
		});
	};
};

export const fliterListTeamSuccess = (response) => {
	return {
		type: FILTER_LIST_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const fliterListTeamError = (response) => {
	return {
		type: FILTER_LIST_TEAM_ERROR,
		payload: response.payload,
	};
};
//REQUEST TO TEAM
export const isRequestingToTeam = () => {
	return {
		type: IS_REQUESTING_TO_TEAM,
	};
};

export const requestToTeam = (idTeam) => {
	return (dispatch, getState) => {
		dispatch(isRequestingToTeam());
		request('api/user/request_to_team', 'POST', {
			body: JSON.stringify({
				id: parseInt(idTeam),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(requestToSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(requestToError(response));
			}
		});
	};
};

export const requestToSuccess = (response) => {
	return {
		type: REQUEST_TO_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const requestToError = (response) => {
	return {
		type: REQUEST_TO_TEAM_ERROR,
		payload: response.payload,
	};
};
//CANCEL REQUEST TO TEAM
export const isCancelRequestingToTeam = () => {
	return {
		type: CANCEL_IS_REQUESTING_TO_TEAM,
	};
};

export const cancelRequestToTeam = (idTeam) => {
	return (dispatch, getState) => {
		dispatch(isCancelRequestingToTeam());
		request('api/user/cancel_request_to_team', 'POST', {
			body: JSON.stringify({
				id: parseInt(idTeam),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(cancelRequestToSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(cancelRequestToError(response));
			}
		});
	};
};

export const cancelRequestToSuccess = (response) => {
	return {
		type: CANCEL_REQUEST_TO_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const cancelRequestToError = (response) => {
	return {
		type: CANCEL_REQUEST_TO_TEAM_ERROR,
		payload: response.payload,
	};
};
//RESPONSE_TEAM_REQUEST
export const isResponsingTeamRequest = () => {
	return {
		type: IS_REQUESTING_TO_TEAM,
	};
};

export const responseTeamRequest = (idPlayer, status) => {
	return (dispatch, getState) => {
		dispatch(isResponsingTeamRequest());
		request('api/user/response_team_request', 'POST', {
			body: JSON.stringify({
				id: parseInt(idPlayer),
				is_accepted: status,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(responseTeamRequestSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(responseTeamRequestError(response));
			}
		});
	};
};

export const responseTeamRequestSuccess = (response) => {
	return {
		type: RESPONSE_TEAM_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const responseTeamRequestError = (response) => {
	return {
		type: RESPONSE_TEAM_REQUEST_ERROR,
		payload: response.payload,
	};
};
// QUIT TEAM
export const isQuitingTeam = () => {
	return {
		type: IS_QUITING_TEAM,
	};
};

export const quitTeam = () => {
	return (dispatch, getState) => {
		dispatch(isQuitingTeam());
		request('api/user/quit_team', 'POST', {
			body: JSON.stringify({}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(quitTeamSuccess(response));
			} else {
				lib.showError(response.error_code);
				dispatch(quitTeamError(response));
			}
		});
	};
};

export const quitTeamSuccess = (response) => {
	return {
		type: QUIT_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const quitTeamError = (response) => {
	return {
		type: QUIT_TEAM_ERROR,
		payload: response.payload,
	};
};
// INVITE PLAYER TO TEAM
export const isInvitingPlayerTeam = () => {
	return {
		type: IS_INVITING_PLAYER_TEAM,
	};
};

export const invitePlayerTeam = (uid) => {
	return (dispatch, getState) => {
		dispatch(isInvitingPlayerTeam());
		request('api/user/invite_player_to_team', 'POST', {
			body: JSON.stringify({
				uid: uid,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(invitePlayerTeamSuccess(response));
				lib.showMessage(
					`Bạn đã gửi lời mời thành công đến huấn luyện viên có UID: ${uid}.`
				);
			} else {
				lib.showError(response.error_code);
				dispatch(invitePlayerTeamError(response));
			}
		});
	};
};

export const invitePlayerTeamSuccess = (response) => {
	return {
		type: INVITE_PLAYER_TEAM_SUCCESS,
		payload: response.payload,
	};
};

export const invitePlayerTeamError = (response) => {
	return {
		type: INVITE_PLAYER_TEAM_ERROR,
		payload: response.payload,
	};
};
// READ NOTIFICATION
export const isReadingNotification = () => {
	return {
		type: IS_READING_NOTIFICATION,
	};
};

export const readNotification = (id, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isReadingNotification());
		request('api/user/read_notification', 'POST', {
			body: JSON.stringify({
				id: parseInt(id),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(readNotificationSuccess(response));
				closeModal();
			} else {
				lib.showError(response.error_code);
				dispatch(readNotificationError(response));
			}
		});
	};
};

export const readNotificationSuccess = (response) => {
	return {
		type: READ_NOTIFICATION_SUCCESS,
		payload: response.payload,
	};
};

export const readNotificationError = (response) => {
	return {
		type: READ_NOTIFICATION_ERROR,
		payload: response.payload,
	};
};
// Response Invitation
export const isResponsingInvitation = () => {
	return {
		type: IS_RESPONSING_NOTIFICATION,
	};
};

export const responseInvitation = (id, status, closeModal) => {
	return (dispatch, getState) => {
		dispatch(isResponsingInvitation());
		request('api/user/response_invitation', 'POST', {
			body: JSON.stringify({
				id: parseInt(id),
				is_accepted: status,
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(responseInvitationSuccess(response));
				if (status) {
					lib.showMessage(`Bạn đã gia nhập đội thành công.`).then((res) => {
						if (getState().currentUser.myInvitations.length == 0) {
							closeModal();
						}
					});
				} else {
					lib
						.showMessage(`Bạn đã từ chối gia nhập đội thành công.`)
						.then((res) => {
							if (getState().currentUser.myInvitations.length == 0) {
								closeModal();
							}
						});
				}
			} else {
				lib.showError(response.error_code);
				dispatch(responseInvitationError(response));
			}
		});
	};
};

export const responseInvitationSuccess = (response) => {
	return {
		type: RESPONSE_NOTIFICATION_SUCCESS,
		payload: response.payload,
	};
};

export const responseInvitationError = (response) => {
	return {
		type: RESPONSE_NOTIFICATION_ERROR,
		payload: response.payload,
	};
};

//GET SHOP
export const isGetShopFetching = () => {
	return {
		type: GET_SHOP_FETCHING,
	};
};

export const getShop = () => {
	return (dispatch, getState) => {
		dispatch(isGetShopFetching());
		request('api/user/get_shop_config').then(function (response) {
			if (response.status == 'success') {
				dispatch(getShopSuccess(response));
			} else {
				dispatch(getShopError(response));
			}
		});
	};
};

export const getShopSuccess = (response) => {
	return {
		type: GET_SHOP_REQUEST_SUCCESS,
		payload: response.payload,
	};
};

export const getShopError = (response) => {
	return {
		type: GET_SHOP_REQUEST_ERROR,
		payload: response.payload,
	};
};

//EXCHANGE SHOP
export const isExchangeShop = () => {
	return {
		type: EXCHANGE_SHOP,
	};
};

export const exchangeShop = (id) => {
	return (dispatch, getState) => {
		dispatch(isExchangeShop());
		request('api/user/exchange_shop_item', 'POST', {
			body: JSON.stringify({
				id: parseInt(id),
			}),
		}).then(function (response) {
			if (response.status == 'success') {
				dispatch(exchangeShopSuccess(response));
			} else {
				dispatch(exchangeShopError(response));
			}
		});
	};
};

export const exchangeShopSuccess = (response) => {
	return {
		type: EXCHANGE_SHOP_SUCCESS,
		payload: response.payload,
	};
};

export const exchangeShopError = (response) => {
	return {
		type: EXCHANGE_SHOP_ERROR,
		payload: response.payload,
	};
};

//EXCHANGE SHOP HISTORY
export const isExchangeShopHistory = () => {
	return {
		type: EXCHANGE_HISTORY_SHOP,
	};
};

export const exchangeShopHistory = () => {
	return (dispatch, getState) => {
		dispatch(isExchangeShopHistory());
		request('api/user/get_user_exchange_history').then(function (response) {
			if (response.status == 'success') {
				dispatch(exchangeShopHistorySuccess(response));
			} else {
				dispatch(exchangeShopHistoryError(response));
			}
		});
	};
};

export const exchangeShopHistorySuccess = (response) => {
	return {
		type: EXCHANGE_SHOP_HISTORY_SUCCESS,
		payload: response.payload,
	};
};

export const exchangeShopHistoryError = (response) => {
	return {
		type: EXCHANGE_SHOP_HISTORY_ERROR,
		payload: response.payload,
	};
};

//GET POINT RANKING
export const isGettingRanking = () => {
	return {
		type: GET_POINT_RANKING,
	};
};

export const getPointRanking = () => {
	return (dispatch, getState) => {
		dispatch(isGettingRanking());
		request('api/user/get_point_ranking').then(function (response) {
			if (response.status == 'success') {
				dispatch(getPointRankingSuccess(response));
			} else {
				dispatch(getPointRankingError(response));
			}
		});
	};
};

export const getPointRankingSuccess = (response) => {
	return {
		type: GET_POINT_RANKING_SUCCESS,
		payload: response.payload,
	};
};

export const getPointRankingError = (response) => {
	return {
		type: GET_POINT_RANKING_ERROR,
		payload: response.payload,
	};
};
