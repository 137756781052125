import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import config from 'config/common';
import queryString from 'query-string';
import request from 'lib/request';

import {
	getCurrentUser,
	getHistory,
	getConfig,
	exchangeShopHistory,
	getPointRanking,
} from 'authentication/actions';

import Header from './Header';

const PageLayout = ({
	lng,
	currentUser,
	getHistory,
	getCurrentUser,
	getConfig,
	location,
	children,
	exchangeShopHistory,
	getPointRanking,
}) => {
	useEffect(() => {
		getCurrentUser();
		getConfig();
		getPointRanking();
		$(window).resize(function () {
			let e = $(window).width();
			e >= 1920
				? $('html').css('font-size', '10px')
				: e >= 1200
				? $('html').css('font-size', (10 * e) / 1920 + 'px')
				: e >= 1100
				? $('html').css('font-size', '10px')
				: $('html').css('font-size', (10 * e) / 1300 + 'px');
		});

		let width = $(window).width();
		let height = $(window).height();
		if (
			(height >= 740 && height <= 768 && width >= 1010 && width <= 1024) ||
			(height >= 1010 && height <= 1024 && width >= 1300 && width <= 1366)
		) {
			$('html').addClass('ipad-view');
		} else {
			$('html').removeClass('ipad-view');
		}

		$(window).trigger('resize');

		const parsed = queryString.parse(location.search);
		const utm_source = parsed.utm_source;
		const garena_token = parsed.garena_token;
		let clientLogged = localStorage.getItem('garena_token');

		if (
			garena_token &&
			garena_token != clientLogged &&
			garena_token.length > 80
		) {
			request(
				`login/callback?access_token=${garena_token}&source_type=ingame`
			).then(function (response) {
				if (response.status == 'successful') {
					localStorage.setItem('garena_token', garena_token);
					window.location.reload();
				} else {
					lib.showError(response.error_code);
				}
			});
		}
	}, []);

	return (
		<>
			<Header
				lng={lng}
				user={currentUser}
				getHistory={getHistory}
				exchangeShopHistory={exchangeShopHistory}
			/>
			<section
				id="main-body"
				className={`page-${
					location.pathname != '/' ? location.pathname.replace('/', '') : ''
				}`}
			>
				<>{children}</>
			</section>
			<footer id="footer">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<img
								src="/images/footer-logo.png?v=1"
								alt=""
								className="footer-logo"
							/>
						</div>
						<div className="col-12">
							<p className="copyright">
								© 2023​ Electronic Arts Inc. Electronic arts, EA, EA Sports,{' '}
								<br />
								The EA sports logo, EA sports FC, And the EA sports FC Logo are
								trademarks of electronic arts inc.
							</p>
						</div>
						{/* <div className="col">
              <img src="/images/age.png" alt="" className="age" />
            </div> */}
					</div>
				</div>
			</footer>
		</>
	);
};

const mapDispatchToProps = {
	getCurrentUser,
	getHistory,
	getConfig,
	exchangeShopHistory,
	getPointRanking,
};

const mapStateToProps = (state) => ({
	currentUser: state.currentUser,
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PageLayout));
