import config from 'config/app';
import lib from 'lib/commons';

import {
	CURRENT_USER_REQUEST_ERROR,
	CURRENT_USER_REQUEST_SUCCESS,
	CURRENT_USER_FETCHING,
	GET_CONFIG_REQUEST_ERROR,
	GET_CONFIG_REQUEST_SUCCESS,
	GET_CONFIG_FETCHING,
	CURRENT_LEAGUE_REQUEST_ERROR,
	CURRENT_LEAGUE_REQUEST_SUCCESS,
	CURRENT_LEAGUE_FETCHING,
	REGISTER_LEAGUE_REQUEST_ERROR,
	REGISTER_LEAGUE_REQUEST_SUCCESS,
	REGISTER_LEAGUE_FETCHING,
	UNREGISTER_LEAGUE_REQUEST_ERROR,
	UNREGISTER_LEAGUE_REQUEST_SUCCESS,
	UNREGISTER_LEAGUE_FETCHING,
	COLLABORATOR_REQUEST_ERROR,
	COLLABORATOR_REQUEST_SUCCESS,
	COLLABORATOR_FETCHING,
	UPDATE_SCORE_ERROR,
	UPDATE_SCORE_SUCCESS,
	UPDATE_SCORE_FETCHING,
	UPDATE_MANUAL_SCORE_ERROR,
	UPDATE_MANUAL_SCORE_SUCCESS,
	UPDATE_MANUAL_SCORE_FETCHING,
	GET_HISTORY_ERROR,
	GET_HISTORY_SUCCESS,
	IS_GETTING_HISTORY,
	UPDATE_INFO_ERROR,
	UPDATE_INFO_SUCCESS,
	IS_UPDATING_INFO,
	CREATE_TEAM_ERROR,
	CREATE_TEAM_SUCCESS,
	IS_CREATING_TEAM,
	CHECK_INVITE_PLAYER_ERROR,
	CHECK_INVITE_PLAYER_SUCCESS,
	IS_CHECKING_INVITE_PLAYER,
	GET_TEAM_ERROR,
	GET_TEAM_SUCCESS,
	IS_GETTING_TEAM,
	DISMISS_TEAM_ERROR,
	DISMISS_TEAM_SUCCESS,
	IS_DISMISSING_TEAM,
	IS_KICKING_PLAYER,
	KICK_PLAYER_SUCCESS,
	KICK_PLAYER_ERROR,
	FILTER_LIST_TEAM_ERROR,
	FILTER_LIST_TEAM_SUCCESS,
	IS_FILTERING_LIST_TEAM,
	REQUEST_TO_TEAM_ERROR,
	REQUEST_TO_TEAM_SUCCESS,
	IS_REQUESTING_TO_TEAM,
	RESPONSE_TEAM_REQUEST_ERROR,
	RESPONSE_TEAM_REQUEST_SUCCESS,
	IS_RESPONSING_TEAM_REQUEST,
	QUIT_TEAM_ERROR,
	QUIT_TEAM_SUCCESS,
	IS_QUITING_TEAM,
	INVITE_PLAYER_TEAM_ERROR,
	INVITE_PLAYER_TEAM_SUCCESS,
	IS_INVITING_PLAYER_TEAM,
	READ_NOTIFICATION_ERROR,
	READ_NOTIFICATION_SUCCESS,
	IS_READING_NOTIFICATION,
	RESPONSE_NOTIFICATION_ERROR,
	RESPONSE_NOTIFICATION_SUCCESS,
	IS_RESPONSING_NOTIFICATION,
	GET_SHOP_REQUEST_ERROR,
	GET_SHOP_REQUEST_SUCCESS,
	GET_SHOP_FETCHING,
	EXCHANGE_SHOP_ERROR,
	EXCHANGE_SHOP_SUCCESS,
	EXCHANGE_SHOP,
	EXCHANGE_SHOP_HISTORY_ERROR,
	EXCHANGE_SHOP_HISTORY_SUCCESS,
	EXCHANGE_HISTORY_SHOP,
	GET_POINT_RANKING_ERROR,
	GET_POINT_RANKING_SUCCESS,
	GET_POINT_RANKING,
	CANCEL_REQUEST_TO_TEAM_ERROR,
	CANCEL_REQUEST_TO_TEAM_SUCCESS,
	CANCEL_IS_REQUESTING_TO_TEAM,
} from './actions';

const initialState = {
	loading: false,
	login: false,
	user: {},
	userHistory: [],
	currentWeekLeagues: [],
	hallOfFames: [],
	currentLeague: {},
	provinces: [],
	serverTimestamp: '',
	collaborator: {},
	team: null,
	myInvitations: [],
	myTeamRequests: [],
	allTeam: [],
	params: {},
	allAddress: {},
	shopItems: [],
	userPoint: 0,
	exchangeHistory: [],
	leagueTypes: [],
	topPointPlayers: [],
	ambassadors: [],

	isGettingPointRanking: false,
	isExchangingShopHistory: false,
	isExchangingShop: false,
	isGettingShop: false,
	isGettingConfig: false,
	isGetCurrentLeague: false,
	isRegisteringLeague: false,
	isUnregisteringLeague: false,
	isGettingCollaboratorLeague: false,
	isUpdatingScore: false,
	isUpdatingManualScore: false,
	isGettingHistory: false,
	isUpdatingInfo: false,
	isCreatingTeam: false,
	isCheckingInvitePlayer: false,
	isGettingTeam: false,
	isDismissingTeam: false,
	isKickingPlayer: false,
	isFilteringListTeam: false,
	isRequestingToTeam: false,
	isResponsingTeamRequest: false,
	isQuitingTeam: false,
	isInvitingPlayerTeam: false,
	isReadingNotification: false,
	isResponsingInvitation: false,
	isCancelRequestingToTeam: false,
};

export default function currentUserReducer(
	state = initialState,
	{ type, payload } = action
) {
	switch (type) {
		case CURRENT_USER_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				login: true,
				user: payload.user,
				errorGlobal: '',
			};
			break;
		case CURRENT_USER_REQUEST_ERROR:
			return {
				...state,
				loading: false,
				errorGlobal: 'Không có thông tin user',
			};
			break;
		case CURRENT_USER_FETCHING:
			return {
				...state,
				loading: true,
			};
			break;

		//CONFIG
		case GET_CONFIG_REQUEST_SUCCESS:
			return {
				...state,
				currentWeekLeagues: payload.current_week_leagues,
				hallOfFames: payload.hall_of_fames,
				provinces: payload.provinces,
				serverTimestamp: payload.server_timestamp,
				params: payload.params,
				allAddress: payload.all_address,
				ambassadors: payload.ambassadors,
				isGettingConfig: false,
			};
			break;
		case GET_CONFIG_REQUEST_ERROR:
			return {
				...state,
				isGettingConfig: false,
			};
			break;
		case GET_CONFIG_FETCHING:
			return {
				...state,
				isGettingConfig: true,
			};
			break;

		//CURRENT LEAGUE
		case CURRENT_LEAGUE_REQUEST_SUCCESS:
			return {
				...state,
				currentLeague: payload.current_league,
				isGetCurrentLeague: false,
			};
			break;
		case CURRENT_LEAGUE_REQUEST_ERROR:
			return {
				...state,
				isGetCurrentLeague: false,
			};
			break;
		case CURRENT_LEAGUE_FETCHING:
			return {
				...state,
				isGetCurrentLeague: true,
			};
			break;

		//REGISTER LEAGUE
		case REGISTER_LEAGUE_REQUEST_SUCCESS:
			return {
				...state,
				currentLeague: payload.current_league,
				isRegisteringLeague: false,
			};
			break;
		case REGISTER_LEAGUE_REQUEST_ERROR:
			return {
				...state,
				isRegisteringLeague: false,
			};
			break;
		case REGISTER_LEAGUE_FETCHING:
			return {
				...state,
				isRegisteringLeague: true,
			};
			break;

		//UNREGISTER LEAGUE
		case UNREGISTER_LEAGUE_REQUEST_SUCCESS:
			return {
				...state,
				currentLeague: payload.current_league,
				isUnregisteringLeague: false,
			};
			break;
		case UNREGISTER_LEAGUE_REQUEST_ERROR:
			return {
				...state,
				isUnregisteringLeague: false,
			};
			break;
		case UNREGISTER_LEAGUE_FETCHING:
			return {
				...state,
				isUnregisteringLeague: true,
			};
			break;

		//COLLABORATOR LEAGUE
		case COLLABORATOR_REQUEST_SUCCESS:
			return {
				...state,
				collaborator: payload,
				isGettingCollaboratorLeague: false,
			};
			break;
		case COLLABORATOR_REQUEST_ERROR:
			return {
				...state,
				isGettingCollaboratorLeague: false,
			};
			break;
		case COLLABORATOR_FETCHING:
			return {
				...state,
				isGettingCollaboratorLeague: true,
			};
			break;

		//UPDATE SCORE
		case UPDATE_SCORE_SUCCESS:
			return {
				...state,
				currentLeague: payload.current_league,
				isUpdatingScore: false,
			};
			break;
		case UPDATE_SCORE_ERROR:
			return {
				...state,
				isUpdatingScore: false,
			};
			break;
		case UPDATE_SCORE_FETCHING:
			return {
				...state,
				isUpdatingScore: true,
			};
			break;

		//UPDATE MANUAL SCORE
		case UPDATE_MANUAL_SCORE_SUCCESS:
			return {
				...state,
				collaborator: payload,
				isUpdatingManualScore: false,
			};
			break;
		case UPDATE_MANUAL_SCORE_ERROR:
			return {
				...state,
				isUpdatingManualScore: false,
			};
			break;
		case UPDATE_MANUAL_SCORE_FETCHING:
			return {
				...state,
				isUpdatingManualScore: true,
			};
			break;

		//GET HISTORY
		case GET_HISTORY_SUCCESS:
			return {
				...state,
				userHistory: payload?.reward_history,
				isGettingHistory: false,
			};
			break;
		case GET_HISTORY_ERROR:
			return {
				...state,
				isGettingHistory: false,
			};
			break;
		case IS_GETTING_HISTORY:
			return {
				...state,
				isGettingHistory: true,
			};
			break;

		//UPDATE INFO
		case UPDATE_INFO_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					user_in_game: {
						...state.user.user_in_game,
						name: payload.name,
						phone: payload.phone,
						facebook: payload.facebook,
						national_id: payload.national_id,
					},
				},
				isUpdatingInfo: false,
			};
			break;
		case UPDATE_INFO_ERROR:
			return {
				...state,
				isUpdatingInfo: false,
			};
			break;
		case IS_UPDATING_INFO:
			return {
				...state,
				isUpdatingInfo: true,
			};
			break;

		//CREATE TEAM
		case CREATE_TEAM_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					user_in_game: {
						...state.user.user_in_game,
						team_id: payload.team.id,
					},
				},
				isUpdatingInfo: false,
			};
			break;
		case CREATE_TEAM_ERROR:
			return {
				...state,
				isUpdatingInfo: false,
			};
			break;
		case IS_CREATING_TEAM:
			return {
				...state,
				isUpdatingInfo: true,
			};
			break;

		//CHECKING_INVITE_PLAYER
		case CHECK_INVITE_PLAYER_SUCCESS:
			return {
				...state,
				isCheckingInvitePlayer: false,
			};
			break;
		case CHECK_INVITE_PLAYER_ERROR:
			return {
				...state,
				isCheckingInvitePlayer: false,
			};
			break;
		case IS_CHECKING_INVITE_PLAYER:
			return {
				...state,
				isCheckingInvitePlayer: true,
			};
			break;

		//GET_TEAM
		case GET_TEAM_SUCCESS:
			return {
				...state,
				team: payload.team,
				myInvitations: payload.my_invitations,
				myTeamRequests: payload.my_team_requests,
				isGettingTeam: false,
			};
			break;
		case GET_TEAM_ERROR:
			return {
				...state,
				isGettingTeam: false,
			};
			break;
		case IS_GETTING_TEAM:
			return {
				...state,
				isGettingTeam: true,
			};
			break;

		//DISMISS TEAM
		case DISMISS_TEAM_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					user_in_game: {
						...state.user.user_in_game,
						team_id: null,
					},
				},
				team: payload.team,
				myInvitations: payload.my_invitations,
				myTeamRequests: payload.my_team_requests,
				isDismissingTeam: false,
			};
			break;
		case DISMISS_TEAM_ERROR:
			return {
				...state,
				isDismissingTeam: false,
			};
			break;
		case IS_DISMISSING_TEAM:
			return {
				...state,
				isDismissingTeam: true,
			};
			break;

		//KICK PLAYER
		case KICK_PLAYER_SUCCESS:
			return {
				...state,
				team: payload.team,
				myInvitations: payload.my_invitations,
				myTeamRequests: payload.my_team_requests,
				isKickingPlayer: false,
			};
			break;
		case KICK_PLAYER_ERROR:
			return {
				...state,
				isKickingPlayer: false,
			};
			break;
		case IS_KICKING_PLAYER:
			return {
				...state,
				isKickingPlayer: true,
			};
			break;
		//FILTER LIST TEAM
		case FILTER_LIST_TEAM_SUCCESS:
			return {
				...state,
				allTeam: payload,
				isFilteringListTeam: false,
			};
			break;
		case FILTER_LIST_TEAM_ERROR:
			return {
				...state,
				isFilteringListTeam: false,
			};
			break;
		case IS_FILTERING_LIST_TEAM:
			return {
				...state,
				isFilteringListTeam: true,
			};
			break;
		//REQUEST TO TEAM
		case REQUEST_TO_TEAM_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				myTeamRequests: payload.my_team_requests,
				isRequestingToTeam: false,
			};
			break;
		case REQUEST_TO_TEAM_ERROR:
			return {
				...state,
				isRequestingToTeam: false,
			};
			break;
		case IS_REQUESTING_TO_TEAM:
			return {
				...state,
				isRequestingToTeam: true,
			};
			break;
		//CANCEL REQUEST TO TEAM
		case CANCEL_REQUEST_TO_TEAM_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				myTeamRequests: payload.my_team_requests,
				isCancelRequestingToTeam: false,
			};
			break;
		case CANCEL_REQUEST_TO_TEAM_ERROR:
			return {
				...state,
				isCancelRequestingToTeam: false,
			};
			break;
		case CANCEL_IS_REQUESTING_TO_TEAM:
			return {
				...state,
				isCancelRequestingToTeam: true,
			};
			break;

		//RESPONSE_TEAM_REQUEST
		case RESPONSE_TEAM_REQUEST_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				team: payload.team,
				isResponsingTeamRequest: false,
			};
			break;
		case RESPONSE_TEAM_REQUEST_ERROR:
			return {
				...state,
				isResponsingTeamRequest: false,
			};
			break;
		case IS_RESPONSING_TEAM_REQUEST:
			return {
				...state,
				isResponsingTeamRequest: true,
			};
			break;

		//QUIT TEAM
		case QUIT_TEAM_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				team: payload.team,
				isQuitingTeam: false,
			};
			break;
		case QUIT_TEAM_ERROR:
			return {
				...state,
				isQuitingTeam: false,
			};
			break;
		case IS_QUITING_TEAM:
			return {
				...state,
				isQuitingTeam: true,
			};
			break;

		//INVITE PLAYER TEAM
		case INVITE_PLAYER_TEAM_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				// team: payload.team,
				isInvitingPlayerTeam: false,
			};
			break;
		case INVITE_PLAYER_TEAM_ERROR:
			return {
				...state,
				isInvitingPlayerTeam: false,
			};
			break;
		case IS_INVITING_PLAYER_TEAM:
			return {
				...state,
				isInvitingPlayerTeam: true,
			};
			break;

		//READ NOTIFICATION
		case READ_NOTIFICATION_SUCCESS:
			return {
				...state,
				// allTeam: payload,
				// myInvitations: payload.my_invitations,
				// team: payload.team,
				isReadingNotification: false,
			};
			break;
		case READ_NOTIFICATION_ERROR:
			return {
				...state,
				isReadingNotification: false,
			};
			break;
		case IS_READING_NOTIFICATION:
			return {
				...state,
				isReadingNotification: true,
			};
			break;

		//response_invitation
		case RESPONSE_NOTIFICATION_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					user_in_game: {
						...state.user.user_in_game,
						team_id: payload.team ? payload.team.id : '',
					},
				},
				myInvitations: payload.my_invitations,
				team: payload.team,
				isResponsingInvitation: false,
			};
			break;
		case RESPONSE_NOTIFICATION_ERROR:
			return {
				...state,
				isResponsingInvitation: false,
			};
			break;
		case IS_RESPONSING_NOTIFICATION:
			return {
				...state,
				isResponsingInvitation: true,
			};
			break;

		//SHOP
		case GET_SHOP_REQUEST_SUCCESS:
			return {
				...state,
				shopItems: payload?.shop_items,
				userPoint: payload?.user_point,
				isGettingShop: false,
			};
			break;
		case GET_SHOP_REQUEST_ERROR:
			return {
				...state,
				isGettingShop: false,
			};
			break;
		case GET_SHOP_FETCHING:
			return {
				...state,
				isGettingShop: true,
			};
			break;

		//EXCHANGE SHOP
		case EXCHANGE_SHOP_SUCCESS:
			return {
				...state,
				shopItems: payload?.shop_items,
				userPoint: payload?.user_point,
				isExchangingShop: false,
			};
			break;
		case EXCHANGE_SHOP_ERROR:
			return {
				...state,
				isExchangingShop: false,
			};
			break;
		case EXCHANGE_SHOP:
			return {
				...state,
				isExchangingShop: true,
			};
			break;

		//EXCHANGE SHOP HISTORY
		case EXCHANGE_SHOP_HISTORY_SUCCESS:
			return {
				...state,
				exchangeHistory: payload?.exchange_history,
				isExchangingShopHistory: false,
			};
			break;
		case EXCHANGE_SHOP_HISTORY_ERROR:
			return {
				...state,
				isExchangingShopHistory: false,
			};
			break;
		case EXCHANGE_HISTORY_SHOP:
			return {
				...state,
				isExchangingShopHistory: true,
			};
			break;

		//GET POINT RANKING
		case GET_POINT_RANKING_SUCCESS:
			return {
				...state,
				leagueTypes: payload?.league_types,
				topPointPlayers: payload?.top_point_players,
				isGettingPointRanking: false,
			};
			break;
		case GET_POINT_RANKING_ERROR:
			return {
				...state,
				isGettingPointRanking: false,
			};
			break;
		case GET_POINT_RANKING:
			return {
				...state,
				isGettingPointRanking: true,
			};
			break;

		default:
			return state;
	}
}
