export default {
  lng: "vi", //vi|en
  region: "VN", //vi|pk
  version: "1",
  domain: "eacc.fo4.garena.vn",
  demo: 0,
  demoMsg: 'Sự kiện sẽ bắt đầu vào ngày 17.04.2022',
  baseUrl:  `https://eacc.fo4.garena.vn`,
  shareHashtag: 'CuongNhietCungEACCSpring2022',
  shareQuote: 'Tham gia cổ vũ rinh ngay quà độc quyền từ FIFA Online 4 ngay thôi!',
  predictGuide: 'https://',
  spinGuide: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/tichdiem_eacc.png',
  prizeList: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/qua_ranking.png',
  prizeListGlobal: 'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_qua.png',
  guideImg: 'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_hd.png',
  prizeName: [
    "5K FC+ Gói 21TOTS 103+",
    "3K FC + Gói 21TOTS 102+",
    "1K FC + Gói 21TOTS 101+",
    "Gói 21TOTS 100+ & 1,000,000,000 BP",
  ],
  resultTitle: {
    "1-0": '[xxx] thắng',
    "9-0": "[xxx] thắng 3 trận",
    "7-1": "[xxx] thắng 2 hòa 1",
    "6-3": "[xxx] thắng 2 thua 1",
    "5-2": "[xxx] thắng 1 hòa 2",
    "3-0": "[xxx] thắng 3 trận",
    "3-1": "[xxx] thắng 3 thua 1",
    "3-2": "[xxx] thắng 3 thua 2",
    "4-4": "[xxx] thắng 1 hòa 1 thua 1",
    "3-3": "2 đội hòa 3 trận",
    "0-9": "[yyy] thắng 3 trận",
    "1-7": "[yyy] thắng 2 hòa 1",
    "3-6": "[yyy] thắng 2 thua 1",
    "2-5": "[yyy] thắng 1 hòa 2",
    "0-3": "[yyy] thắng 3 trận",
    "1-3": "[yyy] thắng 3 thua 1",
    "2-3": "[yyy] thắng 3 thua 2",
    "0-1": '[yyy] thắng',
  },
};
